import React, {Component, Suspense} from 'react'
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { 
    Home,
    myCalculator,
    Login,
    Nosotros,
    Profile,
    Activacion,
    Help,
    Videos,
    Pago,
    Historial,
    Banks,
    Wallets,
    ConfigPage,
    OperacionesEntrantes,
    Recover
} from "views"
import FixedBottom from "layout/fixedBottom"
import Loading from "component/others/Loading"
import ScrollToTop from "component/others/ScrollToTop"
import { AlertMessage } from "component/others/alerts"

export default class myRouter extends Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Loading />
                    <Suspense fallback={<div />}>
                        <Switch>
                            <Route path="/" exact component={Home} />
                            <Route path="/calculadora" exact component={myCalculator} />
                            <Route path="/videos" exact component={Videos} />
                            <Route path="/login" exact component={Login} />
                            <Route path="/nosotros" exact component={Nosotros} />
                            <Route path="/perfil" exact component={Profile} />
                            <Route path="/historial" exact component={Historial} />
                            <Route path="/activacion/:cod" exact component={Activacion} />
                            <Route path="/recover/:cod" exact component={Recover} />
                            <Route path="/ayuda/:tema" component={Help} />
                            <Route path="/pago/:id_cotizacion" component={Pago} />
                            <Route path="/banks" exact component={Banks} />
                            <Route path="/wallets" exact component={Wallets} />
                            <Route path="/configpage" exact component={ConfigPage} />
                            <Route path="/operaciones-entrantes" exact component={OperacionesEntrantes} />
                        </Switch>
                        <FixedBottom />
                        <AlertMessage />
                    </Suspense>
                </ScrollToTop>
            </Router>
        )
    }
}