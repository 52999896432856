import React, { Component } from 'react'
import MediaQuery from 'react-responsive';
import { NavLink } from "react-router-dom"
import { connect } from 'react-redux'

const mapStateToProps = (state) => ({ sesion:state.sesion })
const mapDispatchToProps = dispatch => {
    return{
        loged: () => dispatch({ type:'LOGED' }),
        logout: () => dispatch({ type:'LOGOUT' })
    }
}

class FixedBottom extends Component {
    render() {
        return (
            <div className="fixed-bottom">
                <MediaQuery query="(max-width:769px)">
                    <ul>
                        <li>
                            <NavLink to={"/"} exact activeClassName="active" className="content-fixed-bottom">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/home.svg")})`}} />
                                <div className="text">Inicio</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/nosotros"} exact activeClassName="active" className="content-fixed-bottom">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/aboutus.svg")})`}} />
                                <div className="text">Nosotros</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/calculadora"} exact activeClassName="active" className="content-fixed-bottom content-option-shop">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/money.svg")})`}} />
                                <div className="text">Comprar</div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/videos"} exact activeClassName="active" className="content-fixed-bottom">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/video-camera.svg")})`}} />
                                <div className="text">Videos</div>
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to={"/ayuda/como-comprar-y-vender"} exact activeClassName="active" className="content-fixed-bottom">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/help.svg")})`}} />
                                <div className="text">Ayuda</div>
                            </NavLink>
                        </li> */}
                        <li className={"visible-menu"} data-visible={(this.props.sesion ? 'perfil' : 'login')}>
                            <NavLink to={"/login"} exact activeClassName="active" className="content-fixed-bottom content-fixed-bottom-login">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/profile.svg")})`}} />
                                <div className="text">Usuario</div>
                            </NavLink>
                            <NavLink to={"/perfil"} exact activeClassName="active" className="content-fixed-bottom content-fixed-bottom-perfil">
                                <div className="icon mask-contain" style={{WebkitMaskImage:`url(${require("assets/img/bottomMobile/profile.svg")})`}} />
                                <div className="text">Usuario</div>
                            </NavLink>
                        </li>
                    </ul>
                </MediaQuery>
            </div>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FixedBottom)
