import React, { Component } from 'react'

export class AlertMessage extends Component {
    fnConfigAlert = (e) => {
        window.$('.alert-message').addClass('show');
        setTimeout(() => {
            window.$('.alert-message').removeClass('show');
        }, 5000);
    }
    render() {
        return (
            <div className="alert-message p-fixed">
                <div className="card shadow-lg-2 d-flex flex-row justify-content-center align-items-center">
                    <div className="card-body ArialRoundedMTStd-Regular d-flex align-items-center">
                        <div className="d-flex justify-content-start pl-2 align-items-center fa-2x" style={{width:50}}>
                            <i className="fas fa-exclamation text-danger"></i>
                        </div>
                        <div className="text">
                            Inicie sesión
                        </div>
                        <div className="remove cursor-pointer text-muted" onClick={(e) => {window.$(e.currentTarget).closest('.alert-message').removeClass('show')}}>
                            <i className="fas fa-times"></i>
                        </div>
                        <button className="btn-alert-message d-none" onClick={(e) => this.fnConfigAlert(e)}>Active</button>
                    </div>
                </div>
            </div>
        )
    }
}
