import React from 'react';
import { render } from 'react-dom';
import $ from "jquery"
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'sweetalert2/src/sweetalert2.scss'
import 'assets/scss/index.scss';
import MyRouter from 'router/myRouter';
import * as serviceWorker from './serviceWorker';

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import sesion from './reducers'
const store = createStore(sesion)

window.$ = $;
window.jQuery = window.$;

render(
    <Provider store={store}>
        <MyRouter />
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
