import React, { Component } from 'react'

export default class Loading extends Component {
    render() {
        return (
            <div className="content-loading">
                <div className="spinner-border c-orange" style={{width: "3rem", height: "3rem"}} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}
