let mySesion = (localStorage.getItem('userData') ? true : false);

const sesion = (state = mySesion, action) => {
  switch (action.type) {
    case 'LOGED':
      return true
    case 'LOGOUT':
      return false
    default:
      return state
  }
}
  
export default sesion
  