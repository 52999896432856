import {lazy} from "react"
export const Home= lazy(()=> {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "home" */"./Home"))
    })
})
export const myCalculator= lazy(()=> {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "mycalculator" */"./myCalculator"))
    })
})
export const Login= lazy(()=> {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "myLogin" */"./Login"))
    })
})
export const Profile= lazy(()=> {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "myProfile" */"./Profile"))
    })
})
export const Historial= lazy(()=> {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "myHistorial" */"./Historial"))
    })
})
export const Nosotros= lazy(()=> {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "nosotros" */"./Nosotros"))
    })
})
export const Activacion = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "activacion" */"./Activacion"))
    })
})
export const Recover = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "recover" */"./Recover"))
    })
})
export const Help = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "help" */"./Help"))
    })
})
export const Pago = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "pago" */"./Pago"))
    })
})
export const Videos = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "videos" */"./Videos"))
    })
})
export const Banks = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "banks" */"./Banks"))
    })
})
export const Wallets = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "wallets" */"./Wallets"))
    })
})
export const ConfigPage = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "configpage" */"./ConfigPage"))
    })
})
export const OperacionesEntrantes = lazy(() => {
    return new Promise (resolve => {
        resolve(import(/* webpackChunkName: "operacionesEntrantes" */"./OperacionesEntrantes"))
    })
})